import React, {useState, useEffect} from "react";
import { useWindowWidth } from "../../breakpoints";
import { ObjectOther } from "../../components/ObjectOther";
import { Saly } from "../../components/Saly";
import { SalyWrapper } from "../../components/SalyWrapper";
import "./style.css";

import { TypeAnimation } from 'react-type-animation';
import mixpanel from 'mixpanel-browser';

mixpanel.init("3637e3069cdfb55c6e9e216ff33d7879", {debug: true, ignore_dnt: true});

export const Index = () => {
  const screenWidth = useWindowWidth();

  const onClickStart = async () => {
    mixpanel.track('Click', {location: "start"})
    window.location.href = "https://www.contact.deploywave.com";
    console.log("A")
  }

  const onClickContact = () => {
    mixpanel.track('Click', {location: "contact"})
    window.location.href = "mailto:hello@deploywave.com";
    console.log("B")
  }

  const onClickDocs = async () => {
    mixpanel.track('Click', {location: "docs"})
    window.location.href = "https://www.contact.deploywave.com";
    console.log("C")
  }


  useEffect(() => {
    mixpanel.track("Page View", {
      "Page Name": "Index"
    });
  }, []);

  const roles = ["Cloud Engineer", "DevOps Engineer", "Backend Engineer"];
  const [displayedText, setDisplayedText] = React.useState('')
  const sequence = roles.flatMap((role, index) => [
    role, 
    2000, // Wait 3 seconds before deleting
    () => setDisplayedText(role), // Update displayedText with the current role
    role.split('').map(() => '<backspace>'), // Delete the current role letter by letter
    index < roles.length - 1 ? 500 : 2000, // Wait 0.5 seconds before typing the next role, 3 seconds after the last role before looping
  ]);


  return (
    <div
      className="index"
      style={{
        backgroundColor: screenWidth < 1107 ? "#7086ff" : screenWidth >= 1107 ? "#ffffff" : undefined,
        height: screenWidth < 1107 ? "7182px" : screenWidth >= 1107 ? "6350px" : undefined,
        minWidth: screenWidth < 1107 ? "328px" : screenWidth >= 1107 ? "1107px" : undefined,
        padding: screenWidth < 1107 ? "7px 0px 0px" : undefined,
      }}
    >
      {screenWidth < 1107 && (
        <>
          <div className="navigation-left">
            <img className="logo" alt="Logo" src="/img/logo.png" />
            <div className="navigation-right">
              <div className="text-wrapper" onClick={onClickContact}>Contact</div>
              <div className="text-wrapper" onClick={onClickDocs}>Docs</div>
              <div className="main-cta">
                <div className="overlap-group" onClick={onClickStart}>
                  <div className="rectangle" />
                  <div className="div">Start Building</div>
                </div>
              </div>
            </div>
          </div>
          <div className="page">
            <div className="frame">
              <div className="frame">
                <div className="frame-2">
                <p className="p">We are your AI</p>
              <TypeAnimation
              className="p"
        sequence={sequence}
        wrapper="p"
        cursor={true}
        repeat={Infinity}
      >
                <p className="p">We are your AI {displayedText}<span className="blinking-cursor">|</span></p>
                </TypeAnimation>
                  <div className="text-frame">
                    <p className="deploy-any-backend">
                      <span className="span">Deploy </span>
                      <span className="text-wrapper-2">any</span>
                      <span className="span"> backend with </span>
                      <span className="text-wrapper-2">zero</span>
                      <span className="span"> configuration.</span>
                    </p>
                  </div>
                </div>
                <div className="cta">
                  <div className="explained-cta" onClick={onClickStart}>
                    <div className="explain-your-cta-wrapper">
                      <div className="explain-your-cta">Start building</div>
                    </div>
                  </div>
                  <div className="text-wrapper-3">No credit card required</div>
                </div>
                <div className="group-wrapper">
                  <img className="group" alt="Group" src="/img/group-12aaa-1.png" />
                </div>
              </div>
            </div>
            <div className="frame-3">
              <div className="banner">
                <p className="using-your">
                  <span className="text-wrapper-4">Using your </span>
                  <span className="text-wrapper-5">frameworks </span>
                  <span className="text-wrapper-4">you already</span>
                  <span className="text-wrapper-5"> love</span>
                </p>
              </div>
              <div className="banner-2">
                <img className="r" alt="R" src="/img/r-2.png" />
                <img className="asap-net-logo" alt="Asap net logo" src="/img/asap-net-logo.png" />
                <img className="img" alt="R" src="/img/r.png" />
                <img className="element-flask" alt="Element flask" src="/img/115-1150417-flask-framework-logo.png" />
                <img className="OIP" alt="Oip" src="/img/oip.png" />
                <img className="img-2" alt="Oip" src="/img/oip-2.png" />
              </div>
              <div className="banner-3">
                <img className="img-2" alt="Python logo png open" src="/img/python-logo-png-open-2000.png" />
                <img className="img-3" alt="Oip" src="/img/oip-1.png" />
                <img className="img-2" alt="R" src="/img/r-1-2.png" />
                <img className="img-3" alt="Microsoft azure" src="/img/microsoft-azure-symbol.png" />
                <img className="img-2" alt="R" src="/img/r-1.png" />
                <img className="img-3" alt="Oip" src="/img/image.png" />
                <img className="img-2" alt="Ddd" src="/img/ddd.png" />
              </div>
              <Saly className="saly-36" />
            </div>
          </div>
          <div className="hero-text-lower">
            <div className="decrease-your-time-wrapper">
              <p className="decrease-your-time">
                <span className="text-wrapper-6">Decrease your time to </span>
                <span className="text-wrapper-7">build</span>
                <span className="text-wrapper-6">, </span>
                <span className="text-wrapper-7">iterate</span>
                <span className="text-wrapper-6">, and </span>
                <span className="text-wrapper-7">deploy</span>
                <span className="text-wrapper-6"> cloud applications by </span>
                <span className="text-wrapper-7">300%</span>
                <span className="text-wrapper-6">.</span>
              </p>
            </div>
          </div>
          <div className="frame-4">
            <div className="frame-wrapper">
              <img className="frame-5" alt="Frame" src="/img/frame-4-9.png" />
            </div>
            <div className="div-wrapper">
              <p className="text-wrapper-8">+ 100s of others riding the wave</p>
            </div>
          </div>
          <div className="page-2">
            <div className="frame-6">
              <div className="frame-7">
                <p className="text-wrapper-9">You focus on coding, we focus on deployment.</p>
              </div>
              <div className="connect-any-repo-and-wrapper">
                <p className="div-2">
                  <span className="text-wrapper-10">
                    Connect any repo and tell Wave how you want it deployed - we’ll generate and deploy the requisite
                    infrastructure, implicitly driven from your backend code,{" "}
                  </span>
                  <span className="text-wrapper-2">instantly</span>
                  <span className="text-wrapper-10">. Wave lets teams </span>
                  <span className="text-wrapper-2">build</span>
                  <span className="text-wrapper-10">, </span>
                  <span className="text-wrapper-2">deploy</span>
                  <span className="text-wrapper-10">, and </span>
                  <span className="text-wrapper-2">scale</span>
                  <span className="text-wrapper-10">
                    {" "}
                    apps on AWS without complicated configs or managing infrastructure.
                  </span>
                </p>
              </div>
            </div>
            <div className="cta-2">
              <p className="text-wrapper-11">Use natural language to architect and deploy your infrastructure.</p>
              <div className="explained-cta" onClick={onClickStart}>
                    <div className="explain-your-cta-wrapper">
                      <div className="explain-your-cta">Start building</div>
                    </div>
                  </div>
              <div className="text-wrapper-3">No credit card required</div>
            </div>
            <div className="critique-parent">
              <div className="img-wrapper">
                <img className="saly-2" alt="Saly" src="/img/saly-11.png" />
              </div>
              <div className="frame-8">
                <p className="text-wrapper-12">It’s difficult to implement well architected solutions with IAC</p>
                <p className="text-wrapper-13">
                  It’s almost impossible to implement an optimal backend solution if you aren’t an expert in IAC or
                  cloud configuration.
                </p>
                <div className="frame-9">
                  <img className="vector" alt="Vector" src="/img/vector-2.svg" />
                  <p className="text-wrapper-14">Pre-configured IAC &amp; demos don’t fit  all use cases</p>
                </div>
                <div className="frame-9">
                  <img className="vector" alt="Vector" src="/img/vector-2.svg" />
                  <p className="text-wrapper-14">IAC is different for each cloud platform, even with Terraform</p>
                </div>
              </div>
            </div>
            <div className="critique-parent-2">
              <div className="img-wrapper">
                <img className="saly-3" alt="Saly" src="/img/saly-22.png" />
              </div>
              <div className="frame-10">
                <p className="text-wrapper-15">Other solutions force you to use their frameworks</p>
                <p className="text-wrapper-16">
                  Why modify your code and waste development time at the expense of simplifying deployment? Existing
                  frameworks decrease time to deployment at the expense of versitility.
                </p>
              </div>
              <div className="frame-11">
                <img className="vector" alt="Vector" src="/img/vector-2.svg" />
                <p className="text-wrapper-14">Modifying your backend code costs $</p>
              </div>
              <div className="frame-11">
                <img className="vector" alt="Vector" src="/img/vector-2.svg" />
                <p className="text-wrapper-14">Relying on a specific framework limits your infrastructure</p>
              </div>
            </div>
          </div>
          <div className="page-3">
            <div className="frame-12">
              <p className="deploy-your-code">
                <span className="text-wrapper-6">Deploy your code </span>
                <span className="text-wrapper-17">without</span>
                <span className="text-wrapper-6"> having to read the docs, write IAC, or sit in your browser.</span>
              </p>
              <div className="text-frame">
                <p className="text-wrapper-18">
                  Configuring your cloud apps on the AWS Console is so 2012. Configuring them using Terraform or the CDK
                  is so 2020. Go from code, to deployment, instantly, using machine generated IAC that is derived
                  implicitly from your code.
                </p>
              </div>
              <div className="cta-3">
                <p className="text-wrapper-19">Deploy any repo, any codebase, in one click.</p>
                <div className="explained-cta" onClick={onClickStart}>
                  <div className="overlap-group-3">
                    <div className="explain-your-cta-2">Start building</div>
                  </div>
                </div>
                <div className="text-wrapper-20">No credit card required</div>
              </div>
            </div>
            <div className="frame-13">
              <div className="frame-14">
                <div className="object-other-wrapper">
                  <img className="object-other-2" alt="Object other" src="/img/object-other-09.png" />
                </div>
                <div className="frame-15">
                  <div className="text-wrapper-21">Connect a repo</div>
                  <p className="text-wrapper-22">Start with your backend code</p>
                  <p className="text-wrapper-23">
                    This can be a microservice/http function, a task  that interacts with a database, or just about
                    anything that can be deployed to AWS.
                  </p>
                  <p className="do-you-really-mean">
                    <span className="text-wrapper-24">Do you really mean, </span>
                    <span className="text-wrapper-25">any</span>
                    <span className="text-wrapper-24"> backend?</span>
                  </p>
                  <p className="text-wrapper-26">
                    Yup. We currently support any backend that can be deployed onto AWS.
                  </p>
                </div>
              </div>
              <div className="frame-16">
                <div className="frame-17">
                  <img className="object-other-3" alt="Object other" src="/img/object-other-01.png" />
                </div>
                <div className="frame-18">
                  <p className="text-wrapper-27">Wave will analyze your code</p>
                  <p className="we-ll-automatically">
                    We’ll automatically choose the optimal architecture &amp; configuration based on your code
                  </p>
                  <p className="text-wrapper-28">
                    Tell Wave, in english, what you want to change. Need an API Gateway? A CDN? Just tell Wave.
                  </p>
                  <p className="text-wrapper-29">
                    Make sure to awnser it (if its an objection) otherwise some people might not convert.
                  </p>
                </div>
              </div>
              <div className="frame-19">
                <div className="frame-20">
                  <img className="object-other-4" alt="Object other" src="/img/object-other-19.png" />
                </div>
                <div className="frame-15">
                  <p className="text-wrapper-21">Wave will deploy your code</p>
                  <p className="text-wrapper-22">
                    We’ll give you a link and visual instructions on how to access the newly created resources.
                  </p>
                  <p className="text-wrapper-23">
                    Your code gets deployed, instantly, without any modifications to the codebase needed, to our cloud
                    account. We also provide self-provisioning options.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="page-4">
            <div className="frame-21">
              <p className="everyone-that-rides">
                <span className="text-wrapper-6">Everyone that </span>
                <span className="text-wrapper-17">rides</span>
                <span className="text-wrapper-6"> the Wave iterates </span>
                <span className="text-wrapper-17">faster</span>
                <span className="text-wrapper-6">, and </span>
                <span className="text-wrapper-17">cheaper</span>
                <span className="text-wrapper-6">.</span>
              </p>
              <p className="text-wrapper-30">
                Wave decreases your time-to-market by letting you deploy your code, instantly. Wave saves you money by
                optimally generating your infrastructure based on industry-best practices.
              </p>
            </div>
            <div className="frame-22">
              <div className="bottomnew-wrapper">
                <img className="bottomnew" alt="Bottomnew" src="/img/bottomnew-1.png" />
              </div>
              <p className="wave-lets-us-deploy">
                “Wave lets us deploy our microservice, database, and CDN&nbsp;&nbsp;in one click”
              </p>
              <div className="frame-23">
                <img className="rectangle-3" alt="Rectangle" src="/img/rectangle-19-1.svg" />
                <div className="frame-24">
                  <div className="text-wrapper-31">Tony Reyes</div>
                  <div className="text-wrapper-32">Flamingo Cards Co</div>
                </div>
              </div>
            </div>
            <div className="frame-22">
              <img className="cards" alt="Cards" src="/img/cards1-1-1.png" />
              <p className="text-wrapper-33">“We can now go from repo to deployment in less than 5 minutes”</p>
              <div className="frame-25">
                <img className="group-2" alt="Group" src="/img/group-55-1.png" />
                <div className="frame-24">
                  <div className="frame-24">
                    <div className="text-wrapper-31">Carlos Field</div>
                    <div className="text-wrapper-34">Connected Media Co</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="page-5">
            <div className="frame-26">
              <div className="saly-19-wrapper">
                <SalyWrapper className="saly-19" />
              </div>
              <div className="frame-27">
                <p className="p">
                  <span className="text-wrapper-35">Deploy </span>
                  <span className="text-wrapper-2">any</span>
                  <span className="text-wrapper-35">&nbsp;</span>
                  <span className="text-wrapper-2">backend</span>
                  <span className="text-wrapper-35">, to any </span>
                  <span className="text-wrapper-2">cloud</span>
                  <span className="text-wrapper-35">, instantly.</span>
                </p>
                <div className="text-frame">
                  <p className="div-2">
                    Wave creates the optimal infrastructure for your code - and then deploys it for you. Seriously -
                    Wave derives the backend from your code so that you need to do zero to little configuration in order
                    to deploy it.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {screenWidth >= 1107 && (
        <>
          <div className="navigation-left-2">
            <div className="logo-wrapper">
              <img className="logo-2" alt="Logo" src="/img/logo.png" />
            </div>
            <div className="navigation-right-2">
              <div className="text-wrapper-36" onClick={onClickContact}>Contact</div>
              <div className="text-wrapper-36" onClick={onClickDocs}>Docs</div>
              <div className="main-cta-2" onClick={onClickStart}>
                <div className="group-3">
                  <div className="overlap-group-4">
                    <div className="text-wrapper-37">Start building</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="page-6">
            <div className="frame-28">
              <div className="frame-29">
              <p className="text-wrapper-38">We are your AI</p>
              <TypeAnimation
              className="text-wrapper-38"
        sequence={sequence}
        wrapper="p"
        cursor={true}
        repeat={Infinity}
      >
                <p className="text-wrapper-38">We are your AI {displayedText}<span className="blinking-cursor">|</span></p>
                </TypeAnimation>
                <div className="text-frame-2">
                  <p className="deploy-any-backend-2">
                    <span className="text-wrapper-39">Deploy </span>
                    <span className="text-wrapper-2">any</span>
                    <span className="text-wrapper-39"> backend with </span>
                    <span className="text-wrapper-2">zero</span>
                    <span className="text-wrapper-39"> configuration.</span>
                  </p>
                  <div className="cta-4">
                    <div className="explained-cta-2" onClick={onClickStart}>
                      <div className="overlap-group-5">
                        <div className="explain-your-cta-3">Start building</div>
                      </div>
                    </div>
                    <div className="text-wrapper-40">No credit card required.</div>
                  </div>
                </div>
              </div>
              <img className="group-4" alt="Group" src="/img/group-11-1.png" />
            </div>
            <div className="frame">
              <div className="banner">
                <p className="using-the-frameworks">
                  <span className="text-wrapper-4">Using the </span>
                  <span className="text-wrapper-5">frameworks </span>
                  <span className="text-wrapper-4">you already </span>
                  <span className="text-wrapper-5">love</span>
                </p>
              </div>
              <div className="banner-4">
                <img className="r-2" alt="R" src="/img/r-2.png" />
                <img className="asap-net-logo-2" alt="Asap net logo" src="/img/asap-net-logo.png" />
                <img className="r-3" alt="R" src="/img/r.png" />
                <img className="element-flask-2" alt="Element flask" src="/img/115-1150417-flask-framework-logo.png" />
                <img className="OIP-2" alt="Oip" src="/img/oip.png" />
                <img className="img-4" alt="Oip" src="/img/oip-2.png" />
              </div>
              <div className="banner-4">
                <img className="img-4" alt="Python logo png open" src="/img/python-logo-png-open-2000.png" />
                <img className="img-4" alt="Oip" src="/img/oip-1.png" />
                <img className="img-4" alt="R" src="/img/r-1-2.png" />
                <img className="img-5" alt="Microsoft azure" src="/img/microsoft-azure-symbol.png" />
                <img className="img-4" alt="R" src="/img/r-1.png" />
                <img className="OIP-3" alt="Oip" src="/img/image.png" />
                <img className="img-5" alt="Ddd" src="/img/ddd.png" />
              </div>
              <Saly className="saly-36" />
            </div>
          </div>
          <div className="hero-text-lower-2">
            <div className="frame-30">
              <p className="decrease-your-time-2">
                <span className="text-wrapper-6">Decrease your time to </span>
                <span className="text-wrapper-7">build</span>
                <span className="text-wrapper-6">, </span>
                <span className="text-wrapper-7">iterate</span>
                <span className="text-wrapper-6">, and </span>
                <span className="text-wrapper-7">deploy</span>
                <span className="text-wrapper-6"> cloud applications by </span>
                <span className="text-wrapper-41">300%</span>
                <span className="text-wrapper-6">. </span>
              </p>
              <div className="frame-31" />
              <img className="saly-4" alt="Saly" src="/img/saly-1.png" />
            </div>
            <img className="frame-32" alt="Frame" src="/img/frame-4.png" />
            <div className="frame-33">
              <p className="text-wrapper-42">+ 100s of others riding the wave</p>
            </div>
          </div>
          <div className="page-7">
            <div className="frame-34">
              <p className="text-wrapper-43">You focus on coding, we focus on deployment.</p>
              <div className="text-frame">
                <p className="connect-any-repo-and">
                  <span className="text-wrapper-44">
                    Connect any repo and tell Wave how you want it deployed - we’ll generate and deploy the requisite
                    infrastructure, implicitly driven from your backend code,{" "}
                  </span>
                  <span className="text-wrapper-2">instantly</span>
                  <span className="text-wrapper-44">
                    . Wave lets teams build, deploy, and scale apps on AWS without complicated configs or managing
                    infrastructure.
                  </span>
                </p>
              </div>
            </div>
            <div className="frame-35">
              <p className="text-wrapper-45">Use natural language to architect and deploy your infrastructure.</p>
              <div className="explained-cta-2" onClick={onClickStart}>
                      <div className="overlap-group-5">
                        <div className="explain-your-cta-3">Start building</div>
                      </div>
                    </div>
              <div className="text-wrapper-46">No credit card required.</div>
            </div>
            <div className="critique-parent-3">
              <div className="frame-36">
                <img className="saly-5" alt="Saly" src="/img/saly-11.png" />
              </div>
              <div className="frame-37">
                <p className="text-wrapper-47">It’s difficult to implement well architected solutions with IAC</p>
                <p className="text-wrapper-48">
                  It’s almost impossible to implement an optimal backend solution if you aren’t an expert in IAC or
                  cloud configuration.
                </p>
                <div className="frame-38">
                  <img className="vector" alt="Vector" src="/img/vector.svg" />
                  <p className="text-wrapper-49">Pre-configured IAC &amp; demos don’t fit&nbsp;&nbsp;all use cases</p>
                </div>
                <div className="frame-38">
                  <img className="vector" alt="Vector" src="/img/vector.svg" />
                  <p className="text-wrapper-49">IAC is different for each cloud platform, even with Terraform</p>
                </div>
              </div>
            </div>
            <div className="critique-parent-4">
              <div className="frame-39">
                <p className="text-wrapper-50">Other solutions force you to use their frameworks</p>
                <p className="text-wrapper-51">
                  Why modify your code and waste development time at the expense of simplifying deployment? Existing
                  frameworks decrease time to deployment at the expense of versitility.
                </p>
                <div className="frame-40">
                  <img className="vector-2" alt="Vector" src="/img/vector-2.svg" />
                  <p className="text-wrapper-49">Modifying your backend code costs $</p>
                </div>
                <div className="frame-40">
                  <img className="vector-2" alt="Vector" src="/img/vector-2.svg" />
                  <p className="text-wrapper-49">Relying on a specific framework limits your infrastructure</p>
                </div>
              </div>
              <div className="frame-41">
                <img className="saly-6" alt="Saly" src="/img/saly-22.png" />
              </div>
            </div>
            <div className="page-8">
              <div className="frame-42">
                <div className="deploy-your-code-wrapper">
                  <p className="deploy-your-code-2">
                    <span className="text-wrapper-6">Deploy your code </span>
                    <span className="text-wrapper-17">without</span>
                    <span className="text-wrapper-6"> having to read the docs, write IAC, or sit in your browser.</span>
                  </p>
                </div>
                <div className="text-frame-3">
                  <p className="text-wrapper-52">
                    Configuring your cloud apps on the AWS Console is so 2012. Configuring them using Terraform or the
                    CDK is so 2020. Go from code, to deployment, instantly, using machine generated IAC that is derived
                    implicitly from your code.
                  </p>
                </div>
                <p className="text-wrapper-53">Deploy any repo, any codebase, in one click.</p>
                <div className="explained-cta-2" onClick={onClickStart}>
                  <div className="overlap-group-6">
                    <div className="explain-your-cta-4">Start building</div>
                  </div>
                </div>
                <div className="text-wrapper-54">No credit card required.</div>
              </div>
            </div>
            <div className="frame-43">
              <div className="frame-44">
                <img className="object-other-5" alt="Object other" src="/img/object-other-09.png" />
              </div>
              <div className="frame-45">
                <div className="text-wrapper-55">Connect a repo</div>
                <p className="text-wrapper-56">Start with your backend code</p>
                <p className="text-wrapper-57">
                  This can be a microservice/http function, a task&nbsp;&nbsp;that interacts with a database, or just
                  about anything that can be deployed to AWS.
                </p>
                <p className="do-you-really-mean-2">
                  <span className="text-wrapper-24">Do you really mean, </span>
                  <span className="text-wrapper-25">any</span>
                  <span className="text-wrapper-24"> backend?</span>
                </p>
                <p className="text-wrapper-58">Yup. We currently support any backend that can be deployed onto AWS.</p>
              </div>
            </div>
            <div className="frame-46">
              <div className="frame-45">
                <p className="text-wrapper-59">Wave will analyze your code</p>
                <p className="we-ll-automatically-2">
                  We’ll automatically choose the optimal architecture &amp; configuration based on your code
                </p>
                <p className="text-wrapper-60">
                  Tell Wave, in english, what you want to change. Need an API Gateway? A CDN? Just tell Wave.
                </p>
              </div>
              <div className="object-other-01-wrapper">
                <ObjectOther className="object-other-01" />
              </div>
            </div>
            <div className="frame-43">
              <div className="frame-44">
                <img className="object-other-5" alt="Object other" src="/img/object-other-19.png" />
              </div>
              <div className="frame-45">
                <p className="text-wrapper-55">Wave will deploy your code</p>
                <p className="text-wrapper-56">
                  We’ll give you a link and visual instructions on how to access the newly created resources.
                </p>
                <p className="text-wrapper-57">
                  Your code gets deployed, instantly, without any modifications to the codebase needed, to our cloud
                  account. We also provide self-provisioning options.
                </p>
              </div>
            </div>
          </div>
          <div className="page-9">
            <div className="frame-21">
              <p className="everyone-that-rides-2">
                <span className="text-wrapper-6">Everyone that </span>
                <span className="text-wrapper-17">rides</span>
                <span className="text-wrapper-6"> the Wave iterates </span>
                <span className="text-wrapper-17">faster</span>
                <span className="text-wrapper-6">, and </span>
                <span className="text-wrapper-17">cheaper</span>
                <span className="text-wrapper-6">.</span>
              </p>
              <div className="frame-47">
                <p className="text-wrapper-61">
                  Wave decreases your time-to-market by letting you deploy your code, instantly. Wave saves you money by
                  optimally generating your infrastructure based on industry-best practices.
                </p>
              </div>
            </div>
            <div className="frame-48">
              <div className="bottomnew-wrapper">
                <img className="bottomnew" alt="Bottomnew" src="/img/bottomnew-1.png" />
              </div>
              <div className="frame-49">
                <p className="wave-lets-us-deploy-2">
                  “Wave lets us deploy our microservice, database, and CDN&nbsp;&nbsp;in one click”
                </p>
                <div className="frame-23">
                  <img className="rectangle-4" alt="Rectangle" src="/img/rectangle-19.svg" />
                  <div className="frame-24">
                    <div className="text-wrapper-31">Tony Reyes</div>
                    <div className="text-wrapper-32">Flamingo Cards Co</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="frame-50">
              <div className="frame-51">
                <p className="text-wrapper-62">“We can now go from repo to deployment in less than 5 minutes”</p>
                <div className="frame-25">
                  <img className="group-5" alt="Group" src="/img/group-55-1.png" />
                  <div className="frame-24">
                    <div className="frame-24">
                      <div className="text-wrapper-31">Carlos Field</div>
                      <div className="text-wrapper-34">Connected Media Co</div>
                    </div>
                  </div>
                </div>
              </div>
              <img className="cards-2" alt="Cards" src="/img/cards1-1.png" />
            </div>
          </div>
          <div className="page-10">
            <div className="frame-52">
              <div className="saly-instance-wrapper">
                <SalyWrapper className="saly-instance" />
              </div>
              <div className="frame-53">
                <p className="deploy-any-backend-3">
                  <span className="text-wrapper-63">Deploy </span>
                  <span className="text-wrapper-2">any</span>
                  <span className="text-wrapper-63"> backend, to </span>
                  <span className="text-wrapper-2">any</span>
                  <span className="text-wrapper-63"> cloud, </span>
                  <span className="text-wrapper-2">instantly</span>
                  <span className="text-wrapper-63">.</span>
                </p>
                <div className="text-frame">
                  <p className="text-wrapper-64">
                    Wave creates the optimal infrastructure for your code - and then deploys it for you. Seriously -
                    Wave derives the backend from your code so that you need to do zero to little configuration in order
                    to deploy it.
                  </p>
                </div>
                <button className="button">
                  <button className="overlap-group-7">
                    <button className="explain-your-cta-5">Try for free</button>
                  </button>
                </button>
              </div>
            </div>
          </div>
        </>
      )}

      <div
        className="frame-54"
        style={{
          marginBottom: screenWidth >= 1107 ? "-100.00px" : screenWidth < 1107 ? "-126.00px" : undefined,
        }}
      >
        <p
          className="text-wrapper-65"
          style={{
            alignSelf: screenWidth >= 1107 ? "stretch" : undefined,
            fontSize: screenWidth >= 1107 ? "30px" : screenWidth < 1107 ? "16px" : undefined,
            whiteSpace: screenWidth >= 1107 ? "nowrap" : undefined,
            width: screenWidth < 1107 ? "237px" : undefined,
          }}
        >
          @ Deploy Wave Inc. 2024
        </p>
      </div>
    </div>
  );
};
